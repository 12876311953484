import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import Section, { SectionContent } from "../components/sections/Section";
import LogoImagePath from "../images/logo.png";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Header from "src/elements/Header";
import Footer from "src/elements/Footer";
import theme from "src/components/theme";
import DownloadAppButton from "../elements/DownloadAppButton";

const DownloadContainer = styled(Section)`
  position: relative;
  min-height: 787px;

  &:before {
    background-image: linear-gradient(to top, #a8a8bb, #323249);
    background-size: cover;
    background-position: 50% 50%;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 787px;
    z-index: -2;
  }

  ${SectionContent} {
    padding: 0;

    @media (min-width: ${theme.mobileBreakpoint}) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
`;

const DownloadPageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px 20px 0 20px;
`;

const ImageContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  margin: 100px auto 0;
`;

const TrialPage = () => {
  const data = useStaticQuery(graphql`
    query DownloadPage {
      file(relativePath: { eq: "features/electron.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
    }
  `);

  return (
    <Layout mainPage header={null} footer={<Footer />}>
      <div itemScope itemType="http://schema.org/SoftwareApplication">
        <DownloadContainer>
          <Header />
          <DownloadPageContent>
            <h1>AppSpector for Mac</h1>
            <p>Encrypt an application data with end-to-end encryption and launch right from your dock.</p>
            <DownloadAppButton />
            <ImageContainer>
              <Img image={data.file.childImageSharp.gatsbyImageData} />
            </ImageContainer>
          </DownloadPageContent>
        </DownloadContainer>
        <meta itemProp="applicationCategory" content="Developer Tools" />
        <meta itemProp="name" content="AppSpector" />
        <meta itemProp="downloadUrl" content="https://app.appspector.com/register" />
        <meta itemProp="logo" content={`https://appspector.com${LogoImagePath}`} />
      </div>
    </Layout>
  );
};

TrialPage.propTypes = {};

export default TrialPage;
